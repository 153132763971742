import { createContext, useContext, useState, useRef, useEffect } from "react";
import { getJmrvAdminData, getJmrvAdminPercent } from "../../MapBase/helpers";

const SelectionContext = createContext();

export function SelectionProvider({ children }) {
  // panel and map
  const chartData = null;
  const baselineData = null;
  const gid = "USA";
  const adminLevel = 0;
  const displayText = "United States";
  const gidRef = useRef(gid);

  const [allMapData, setAllMapData] = useState({
    gid,
    adminLevel,
    displayText,
    chartData,
    baselineData,
  });

  const setSelection = (gid, adminLevel) => {
    gidRef.current = gid;
    setAllMapData((prevData) => ({
      ...prevData,
      gid,
      adminLevel,
    }));
  };

  const setDisplayText = (displayText) => {
    setAllMapData((prevData) => ({
      ...prevData,
      displayText,
    }));
  };

  const fetchPanelData = async (gid, adminLevel) => {
    const baselineData = await getJmrvAdminPercent(gid);
    const chartData = await getJmrvAdminData(gid);

    setAllMapData((prevData) => ({
      ...prevData,
      chartData,
      baselineData,
    }));
  };

  useEffect(() => {
    gidRef.current = gid;
  }, [allMapData.gid]);

  useEffect(() => {
    fetchPanelData(allMapData.gid, allMapData.adminLevel);
  }, [allMapData.adminLevel, allMapData.gid]);

  useEffect(() => {}, [allMapData]);

  const currentSelection = {
    gidRef,
    gid: allMapData.gid,
    adminLevel: allMapData.adminLevel,
    displayText: allMapData.displayText,
    chartData: allMapData.chartData,
    baselineData: allMapData.baselineData,
    baselineInfo: allMapData.baselineInfo,
    setSelection,
    setDisplayText,
    setAllMapData,
    allMapData,
  };

  return (
    <SelectionContext.Provider value={{ currentSelection }}>
      {children}
    </SelectionContext.Provider>
  );
};

export function useCurrentSelection() {
  const context = useContext(SelectionContext);

  if (context === undefined) {
    throw new Error(
      "useCurrentSelection must be used within a SelectionProvider"
    );
  }

  return context;
};
