// map configuration
export const minZoom = 3;
export const maxZoom = 14;

export const mapStyle = {
  gidOnLoad: "USA",
  center: [-95.712891, 37.09024], // USA (longitude, latitude)
  style: "mapbox://styles/mapbox/light-v11",
  maxZoom: 11,
  minZoom: 3,
  tileserverOnLoad:
    "https://tileserver-biomass-2020-100m.s3.us-west-2.amazonaws.com/tileserver-biomass-2024-10-31-v2/{z}/{x}/{y}.png",
};

export const header = {
  title: "Jurisdictional MRV (JMRV) Platform",
  desc: "Measure, report, and verify carbon stocks, emissions, and removals in all jurisdictions globally.",
  img: "https://ctrees-website.s3.amazonaws.com/website_images/product/jmrv_pg.jpg",
};

export const featsAndBenefits = [
  {
    title: "Available now",
    desc: "Our free jurisdictional tool provides annual data for more than 185 countries and their subnational divisions – and advanced JMRV features are available through partnerships",
  },
  {
    title: "Activity data",
    desc: "Measure and report areas of deforestation, degradation, fire, and other land use activities.",
  },
  {
    title: "Global stocktake",
    desc: "We collaborate with nations and jurisdictions of the global south to integrate national and local data and continuously improve the state of global stocktake.",
  },
  {
    title: "Consistent at all scales",
    desc: "We scale up carbon fluxes originated from activities at areas less than 0.1 hectare to subnational, national, and global levels for consistent carbon accounting.",
  },
  {
    title: "Standard compatible",
    desc: "Use CTrees data to build and evaluate jurisdictional carbon credit programs under major standards.",
  },
];

// for each paragraph in methodology description, add as item inside of method array
export const desc = [
  "Under the Paris Agreement, jurisdictional scale (national and subnational) REDD+ became the prominent focus of climate change mitigation commitments in developing countries. Provisions of Article 6 also allow for transaction of market-based carbon credits and non-market cooperation among jurisdictions. Meanwhile, standards such as ART/TREES and Verra’s Jurisdictional and Nested REDD+ (JNR) Framework have been designed to implement jurisdictional REDD+, and to catalyze large-scale finance for REDD+ carbon credits [1][2].",
  "The JMRV platform accurately estimates carbon stocks, emissions and removals from land use activities, for all jurisdictions and countries globally, to ensure that carbon credits issued by jurisdictions and progress towards nationally determined contributions (NDCs) are real, measured, permanent, additional, not double counted, and are net of leakage. Our methodology follows peer-reviewed techniques established by our team and others in scientific literature, but can be readily integrated in standards such as ART/TREES and JNR [3][4].",
  "Applying CTrees carbon maps and activity data, the JMRV methodology includes three distinct processes to scale data from <0.1 hectare measurements to accurate jurisdiction and country estimates: (1) Annual estimation of areas of deforestation, degradation, fire, forests regeneration, quantified at < 0.1 ha, are scaled to jurisdictional levels for annual reporting. (2) Emission factors based on 1-ha carbon maps are scaled and reported for all jurisdictions without national forest inventories (NFI), or adjusted for all jurisdictions with NFI, to remove any systematic error to improve the precision of estimates. (3) Emissions and removals from activity data are calculated at the local scale (1-ha pixels) and scaled up to jurisdictions along with confidence levels for annual reporting.",
  "The spatial nature of CTrees’ land carbon and activity data allows for customization of JMRV by integrating information on land tenure, protected areas, existing carbon projects, and inventory in the process of quantifying subnational carbon credits. CTrees has engaged with standards such as Verra and has developed bilateral and multilateral partnerships with country and state ministries to broaden the scope of JMRV and provide technical support. CTrees works closely with partner nations and jurisdictions in the global south to continuously improve CTrees datasets by integrating national and local data into our platform.",
];
// for each numbered reference under methodology, add as item inside of superscript array
export const superscript = [
  "[1] Architecture for REDD+ Transactions (ART) under The REDD+ Environmental Excellence Standard (TREES)",
  "[2] Verified Carbon Standard (VCS) Jurisdictional and Nested REDD+ (JNR) Framework",
  "[3] Xu, L., Saatchi, S. S., Yang, Y., Yu, Y., Pongratz, J., Bloom, A. A., ... & Schimel, D. (2021). Changes in global terrestrial live biomass over the 21st century. Science Advances, 7(27), eabe9829.",
  "[4] Bultan, S., Nabel, J. E., Hartung, K., Ganzenmüller, R., Xu, L., Saatchi, S., & Pongratz, J. (2022). Tracking 21st century anthropogenic and natural carbon fluxes through model-data integration. Nature Communications, 13(1), 5516.",
];

export const faq = [
  { question: "What metrics are included in the platform?", answer: "bye" },
  { question: "hi", answer: "bye" },
];
