import { Map } from "@ctrees-products/web-mapbox-interactivity";
import { mapStyle } from "../../constants";
import { useCurrentSelection, useCurrentMap } from "../../../contexts";

export default function Mapbox() {
  const { currentSelection } = useCurrentSelection();
  const { currentMap } = useCurrentMap();
  
  return (
    <div className="relative">
      <Map
        currentSelection={currentSelection}
        currentMap={currentMap}
        mapStyle={mapStyle}
        className="absolute w-full h-screen"
      />
    </div>
  );
};
