const apiPrefix = `${process.env.REACT_APP_API_URL}/api/${process.env.REACT_APP_API_VERSION}`;
const jmrvEndpoint = "/jmrv/data_categorized?gid_value=";
const jmrvPercent = "/jmrv/data_baseline";

export async function getJmrvAdminData(gid) {
  const url = `${apiPrefix}${jmrvEndpoint}${gid}`;
  const response = await fetch(url);

  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else {
    return;
  }
}

export async function getJmrvAdminPercent(gid) {
  const response = await fetch(`${apiPrefix}${jmrvPercent}?gid_value=${gid}`);
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else {
    return;
  }
}
